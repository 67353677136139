/* src/App.css */
.input-zone {
  margin-bottom: 20px;
}
.hidden {
  display: none;
}
#quiz-container {
  margin-top: 20px;
}
#word, #definition {
  font-size: 1.5em;
  margin-bottom: 10px;
}
.button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}
.level-counter {
  margin-top: 20px;
}
.validation-status {
  margin-left: 10px;
  font-weight: bold;
}
.valid {
  color: green;
}
.invalid {
  color: red;
}
#loading {
  font-size: 1.2em;
  font-weight: bold;
  color: blue;
}

#error-message {
  font-size: 1.2em;
  font-weight: bold;
  color: red;
}
.bucket-counter {
  margin-top: 20px;
}
